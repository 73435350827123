//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-40:_5176,_8088,_3148,_9304,_996,_948,_9616,_7640;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-40')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-40', "_5176,_8088,_3148,_9304,_996,_948,_9616,_7640");
        }
      }catch (ex) {
        console.error(ex);
      }