//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-40:_3272,_3216,_6200,_9476,_8863,_2136,_5804,_1396;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-40')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-40', "_3272,_3216,_6200,_9476,_8863,_2136,_5804,_1396");
        }
      }catch (ex) {
        console.error(ex);
      }