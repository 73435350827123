//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-40:_8399,_4720,_7360,_7784,_3696,_8420,_7458,_3216;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-40')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-40', "_8399,_4720,_7360,_7784,_3696,_8420,_7458,_3216");
        }
      }catch (ex) {
        console.error(ex);
      }